import React, { useRef } from 'react';
import { JavascriptViewer } from "@3dweb/360javascriptviewer";

function Viewer360({ imageUrlFormat, totalFrames, imageSrc}) {
    const holderRef = useRef(null);
 
    const jsv = new JavascriptViewer({
        imageUrlFormat: imageUrlFormat,
        totalFrames: totalFrames
    });

    let allowLooping = true;

    const loop = () => {
        if (allowLooping) {
        jsv.rotateDegrees(-5).then(() => {
            loop();
        });
        }
    };

    jsv.events().started.on(() => {
        loop();
    });

    jsv.start()
        .then(() => console.log('viewer started'))
        .catch((e) => console.error('failed loading 360 viewer:', e));

    return (
    <div className="viewer-360-container">
        <div id="jsv-holder" ref={holderRef}>
        <img 
            id="jsv-image" 
            alt="360 view of artifact" 
            src={imageSrc}
        />
        </div>
    </div>
    );
}

export default Viewer360; 