import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import ThreeViewer from './threejs/viewer'
import { StlViewer } from 'react-stl-viewer'
import axios from 'axios';
import { HobbyistPayment } from './components/HobbyistPayment';
import Viewer360 from './components/Viewer360'

function AppContent() {
  const [activeTab, setActiveTab] = useState('description');
  const [showAllResearchers, setShowAllResearchers] = useState(false);
  const [researcherData, setResearcherData] = useState([]);
  const [showAllCommercialAccess, setShowAllCommercialAccess] = useState(false);
  const [commercialAccessData, setCommercialAccessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stlError, setStlError] = useState(null);
  const [nftData, setNftData] = useState(null);
  const [nftError, setNftError] = useState(null);
  const [viewerMounted, setViewerMounted] = useState(false);

  const cameraRef = useRef();

  const handleStlError = (error) => {
    console.error("Error loading STL file:", error);
    setStlError("Failed to load 3D model. Please try again later.");
  }

  // Add this function near your other handlers
  const handleStripePayment = () => {
    // Replace this URL with your actual Stripe Payment Link
    window.location.href = 'https://buy.stripe.com/test_aEU6qj4jt8Ybbmg6oo';
  };

  useEffect(() => {
    const stlPath = '/Model/Xenoceratops_Skull_Print.stl';
    
    fetch(stlPath)
      .then(response => {
        if (!response.ok) {
          throw new Error('STL file not found');
        }
      })
      .catch(error => {
        console.error("Error checking STL file:", error);
        setStlError("3D model file not found. Please check the file path.");
      });

    // Fetch NFT data from OpenSea API
    const fetchNftData = async () => {
      try {
        const response = await axios.get(
          'https://testnets-api.opensea.io/v2/chain/sepolia/contract/0x28b105a310388815e7757397f79b9972e0fd2470/nfts/2',
          {
            headers: {
              'X-API-KEY': 'YOUR_OPENSEA_API_KEY' // Replace with actual API key
            }
          }
        );
        setNftData(response.data.nft);
      } catch (error) {
        console.error("Error fetching NFT data:", error);
        setNftError("Failed to load NFT data. Please try again later.");
      }
    };

    fetchNftData();
  }, []);

  useEffect(() => {
    console.log('Viewer container dimensions:', {
      height: document.querySelector('.viewer-wrapper')?.clientHeight,
      width: document.querySelector('.viewer-wrapper')?.clientWidth
    });
  }, []);

  const style = {
    top: 0,
    left: 0,
    width: '100%',
    height: '400px',
  }

  return (
    <div className="app-container">
      <nav className="main-nav">
        <span className="logo">© Research Casting International</span>
        <div className="nav-links">
          <a href="#dinosauria">HOME</a>
          <a href="#institutions">INSTITUTIONS</a>
          <a href="#collections">FEATURED</a>
          <a href="#shop">Explore</a>
          <a href="#community">Shop</a>
          <a href="#about">ABOUT</a>
        </div>
      </nav>

      <div className="content-wrapper">
        <header className="specimen-header">
          <h1 className="specimen-title">XENOCERATOPS</h1>
          <p className="specimen-subtitle">
            LATE CRETACEOUS PERIOD • ALBERTA, CANADA • SERIES 1/500
          </p>
        </header>

        <main className="specimen-content">
          <div className="content-layout">
            {/* Left Column - 3D Viewer */}
            <section className="model-showcase">
              <div className="model-container">
                <div 
                  className="viewer-wrapper"
                  style={{ 
                    // border: '1px solid red', // Temporary to see the container
                    minHeight: '500px',
                    width: '100%',
                    position: 'relative'
                  }}
                >
                  {console.log('Rendering ThreeViewer')}
                  <Viewer360
                    imageUrlFormat={'0xxx.png'}
                    totalFrames={150}
                    imageSrc={'/zuul/shell/0001.png'}
                  />
                </div>
                {!viewerMounted && <div>Loading viewer...</div>}
                {/* <div className="model-controls">
                  <button className="control-btn">Rotate</button>
                  <button className="control-btn">Pan</button>
                  <button className="control-btn">Reset</button>
                </div> */}
              </div>
            </section>

            {/* Right Column - Info */}
            <section className="specimen-info">
              <div className="info-tabs">
                <div className="tab-buttons">
                  <button 
                    className={`tab-btn ${activeTab === 'description' ? 'active' : ''}`}
                    onClick={() => setActiveTab('description')}
                  >
                    DESCRIPTION
                  </button>
                  <button 
                    className={`tab-btn ${activeTab === 'metadata' ? 'active' : ''}`}
                    onClick={() => setActiveTab('metadata')}
                  >
                    METADATA
                  </button>
                </div>

                <div className="tab-content">
                  {activeTab === 'description' ? (
                    <div className="description-content">
                      <p>
                        Xenoceratops is a centrosaurine horned-dinosaur only known from several partial skulls. 
                        It has a characteristic short, flat spike at the back corners of its frill, and two short, 
                        thick hooks that point forward.
                      </p>
                      <p>
                        Xenoceratops is one of the oldest centrosaurines known. These early centrosaurines had 
                        long horns over their eyes and a low, long ridge over the nose. In centrosaurines that 
                        evolved later, this nasal ridge developed into a long horn, but the horns over their 
                        eyes became reduced.
                      </p>
                      <p>
                        Not much is known about the lifestyle of Xenoceratops but recently discovered bonebeds 
                        suggest that it probably lived in herds like most other centrosaurs.
                      </p>
                    </div>
                  ) : (
                    <div className="metadata-content">
                      <div className="metadata-grid">
                        <div className="metadata-item">
                          <h4>Scientific Name</h4>
                          <p>Xenoceratops foremostensis</p>
                        </div>
                        <div className="metadata-item">
                          <h4>Time Period</h4>
                          <p>Late Cretaceous (78.5-77.5 MYA)</p>
                        </div>
                        <div className="metadata-item">
                          <h4>Location</h4>
                          <p>Alberta, Canada</p>
                        </div>
                        <div className="metadata-item">
                          <h4>Length</h4>
                          <p>Estimated 6 meters (20 feet)</p>
                        </div>
                        <div className="metadata-item">
                          <h4>Discovery</h4>
                          <p>First described in 2012</p>
                        </div>
                        <div className="metadata-item">
                          <h4>Collection</h4>
                          <p>Royal Ontario Museum</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>

          {/* Access Tiers Section */}
          <section className="access-tiers">
            <div className="tier hobbyist-tier">
              <div className="tier-header">
                <h2>HOBBYIST ACCESS</h2>
                <p className="tier-price">$29.99</p>
                <div className="tier-subtitle">Non-Commercial License</div>
              </div>
              <div className="tier-preview">
                <StlViewer
                  style={style}
                  orbitControls={true}
                  shadows={false}
                  url={'/Model/Xenoceratops_Skull_Print.stl'}
                  cameraProps={{
                    ref: cameraRef,
                    initialPosition: { latitude: 0, longitude: 0, distance: 1 }
                  }}
                  modelProps={{ scale: .9 }}
                  modeldimensions={{ boundingRadius: 100 }}
                  onError={handleStlError}
                />
              </div>
              <div className="tier-features">
                <ul>
                  <li>✓ High-quality 3D printable STL file</li>
                  <li>✓ Basic texture maps</li>
                  <li>✓ Assembly instructions</li>
                  <li>✓ Pre-supported available</li>
                  <li>✓ Non-commercial license</li>
                  
                </ul>
              </div>
              <button onClick={handleStripePayment} className="tier-cta">
                Purchase Files
              </button>
            </div>

            <div className="tier collector-tier">
              <div className="tier-header">
                <h2>COLLECTOR ACCESS</h2>
                <div className="nft-badge">NFT</div>
              </div>
              <div className="tier-preview">
                {nftData && (
                  <div className="nft-preview">
                    <img src={nftData.image_url} alt={nftData.name} />
                    <div className="nft-overlay">
                      <span>View on OpenSea</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="tier-features">
                <ul>
                  <li>✓ Museum-grade 3D files</li>
                  <li>✓ High-resolution textures</li>
                  <li>✓ Scientific documentation</li>
                  <li>✓ Ownership verification on blockchain</li>
                  <li>✓ Commercial rights available</li>
                </ul>
              </div>
              <a 
                href="https://testnets.opensea.io/assets/sepolia/0x28b105a310388815e7757397f79b9972e0fd2470/2"
                target="_blank"
                rel="noopener noreferrer"
                className="tier-cta collector"
              >
                View on OpenSea
              </a>
            </div>

            <div className="tier research-tier">
              <div className="tier-header">
                <h2>RESEARCH ACCESS</h2>
                <div className="access-badge">Academic</div>
              </div>
              <div className="tier-preview">
                <div className="research-preview">
                  <img src="/images/research-preview.jpg" alt="Research Access" />
                  <div className="research-overlay">
                    <span>View Research Records</span>
                  </div>
                </div>
              </div>
              <div className="tier-features">
                <ul>
                  <li>✓ High-resolution scan data</li>
                  <li>✓ Complete measurement data</li>
                  <li>✓ Research documentation</li>
                  <li>✓ Academic collaboration</li>
                  <li>✓ Blockchain verification</li>
                </ul>
              </div>
              <button
                className="tier-cta research"
              >
                {showAllResearchers ? 'Hide Records' : 'View Research Records'}
              </button>
              {showAllResearchers && (
                <div className="access-records">
                  {researcherData.map((researcher, index) => (
                    <div key={index} className="record-item">
                      <strong>{researcher.researcherName}</strong>
                      <span>{researcher.institution}</span>
                      <span>{new Date(Number(researcher.timestamp) / 1000000).toLocaleDateString()}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="tier commercial-tier">
              <div className="tier-header">
                <h2>COMMERCIAL ACCESS</h2>
                <div className="access-badge">Enterprise</div>
              </div>
              <div className="tier-preview">
                <div className="commercial-preview">
                  <img src="/images/commercial-preview.jpg" alt="Commercial Access" />
                  <div className="commercial-overlay">
                    <span>View Commercial Records</span>
                  </div>
                </div>
              </div>
              <div className="tier-features">
                <ul>
                  <li>✓ Commercial license</li>
                  <li>✓ Exhibition rights</li>
                  <li>✓ Marketing materials</li>
                  <li>✓ Technical support</li>
                  <li>✓ Custom solutions</li>
                </ul>
              </div>
              <button 
                className="tier-cta commercial"
              >
                {showAllCommercialAccess ? 'Hide Records' : 'View Commercial Records'}
              </button>
              {showAllCommercialAccess && (
                <div className="access-records">
                  {commercialAccessData.map((access, index) => (
                    <div key={index} className="record-item">
                      <strong>{access.displayName}</strong>
                      <span>{access.institution}</span>
                      <span>{new Date(Number(access.timestamp) / 1000000).toLocaleDateString()}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>

          {/* Access Records Section */}
          <section className="access-records">
            {/* ... keep existing commercial and researcher sections ... */}
          </section>
        </main>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
